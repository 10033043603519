// common business logic related util methods
import axios from "axios";
import { baseUri } from "../config/config";
import { QueryClient } from "react-query";
const queryClient = new QueryClient();
axios.defaults.headers.post["Content-Type"] = "application/json";
const _get_defaultLangugae_from_localStorage = () => {
  let lang = localStorage.getItem("defaulLanguagae") || "ENGLISH";

  try {
    lang = JSON.parse(lang);
  } catch (error) {}

  return lang.toUpperCase();
};

const _url = "https://cors-anywhere.herokuapp.com/";
export async function invokeApi({
  uri = baseUri,
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  postData = {},
  multilanguage_baseuri = "",
}) {
  const defaultLanguage = _get_defaultLangugae_from_localStorage();
  // console.log(defaultLanguage, "defaultLanguagedefaultLanguage");
  const updatedHeaders = {
    ...headers,
    language: defaultLanguage,
  };
  const reqObj = {
    method,
    // url: uri + path,
    url:
      multilanguage_baseuri !== "" ? multilanguage_baseuri + path : uri + path,
    headers: updatedHeaders,
  };

  reqObj.params = queryParams;

  if (method === "POST") {
    reqObj.data = postData;
  }
  if (method === "PUT") {
    reqObj.data = postData;
  }
  if (method === "DELETE") {
    reqObj.data = postData;
  }

  let results;

  console.log("<===REQUEST-OBJECT===>", reqObj);

  try {
    results = await axios(reqObj);
    console.log("<===Api-Success-Result===>", results);

    return results.data;
  } catch (error) {
    console.log("<===Api-Error===>", { ...error, path }, path);

    if (error.response.status === 401) {
      localStorage.clear();
      queryClient.clear();
      window.location.reload();
    }
    return {
      code: error.response.status,
      message: error.response.data.message ? error.response.data.message : "",
    };
  }
}
